import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { isNotNil } from '@engineering11/utility'
import { TranslateModule } from '@ngx-translate/core'
import { IFileReceipt } from '@engineering11/files-web'

@Component({
  selector: 'automation-video-url-preview',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  template: `
    <div class="e11-flex e11-flex-row e11-gap-2 e11-flex-wrap e11-my-2" *ngIf="_fileReceipts?.length">
      <div class="e11-relative e11-group e11-w-56" *ngFor="let fileReceipt of _fileReceipts">
        <video controls class="e11-w-full e11-rounded-md e11-ring-1 e11-ring-gray-300 hover:e11-bg-gray-100">
          <source [src]="fileReceipt.url" type="video/mp4" />
          {{ 'Your browser does not support the video tag.' | translate }}
        </video>
        <span
          *ngIf="showRemoveButton"
          class="e11-absolute e11-cursor-pointer e11-hidden group-hover:e11-block e11-top-0 e11-right-1 e11-h-5 e11-w-5 e11--translate-y-1/2 e11-translate-x-1/2 e11-transform e11-rounded-full e11-bg-white"
          (click)="onRemoveFileReceipt(fileReceipt.id)"
        >
          <span class="material-icons e11-text-gray-400 e11-text-[20px]">cancel</span>
        </span>
      </div>
    </div>

    <div class="e11-flex e11-flex-row e11-gap-2 e11-flex-wrap e11-my-2" *ngIf="_fileDataUrl">
      <div
        class="e11-relative e11-group"
        [ngClass]="{
          'e11-w-1/3 e11-mx-auto': largeDisplay,
          'e11-w-56': !largeDisplay
        }"
      >
        <video controls class="e11-w-full e11-rounded-md e11-ring-1 e11-ring-gray-300 hover:e11-bg-gray-100">
          <source [src]="_fileDataUrl" type="video/mp4" />
          {{ 'Your browser does not support the video tag.' | translate }}
        </video>
      </div>
    </div>
  `,
})
export class AutomationVideoUrlPreviewComponent {
  _fileReceipts: IFileReceipt[] = []
  @Input() set fileReceipts(value: IFileReceipt[] | undefined) {
    this._fileReceipts = value ? value.filter(isNotNil) : []
  }
  _fileDataUrl?: string
  @Input() set fileBlob(value: File | undefined) {
    this.getDataUrls([value].filter(isNotNil)).then(urls => {
      this._fileDataUrl = urls[0]
    })
  }
  @Input() loading = false
  @Input() showRemoveButton = true
  @Input() largeDisplay = false
  @Output() removeFileReceipt = new EventEmitter<string>()
  @Output() removeFileBlob = new EventEmitter<number>()

  onRemoveFileReceipt(fileReceiptId: string) {
    this.removeFileReceipt.emit(fileReceiptId)
  }

  onRemoveFileBlob(index: number) {
    this.removeFileBlob.emit(index)
  }

  async getDataUrls(file: File[]): Promise<string[]> {
    const url = await Promise.all(file.map(f => this.fileToDataURL(f)))
    return url
  }

  fileToDataURL(file: File): Promise<string> {
    var reader = new FileReader()
    return new Promise(function (resolve, reject) {
      reader.onload = function (event: any) {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })
  }
}
