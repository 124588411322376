import { Validators } from '@angular/forms'
import {
  e11FieldInputDynamicComponent,
  e11FieldInputNumber,
  e11FieldInputSelect,
  e11FieldInputSwitch,
  e11FieldInputTextArea,
  e11FormEventHelper,
  e11FormFor,
  E11FormForType,
  e11FormLayoutFullWidth,
  e11FormLayoutInlineGrid,
  e11LayoutGroup,
  IFormTemplateSpec,
} from '@engineering11/ui-lib/e11-form-builder'
import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { IMarkdownFile, IEditorMessage } from '@engineering11/ui-lib/e11-markdown-editor'
import { INPUTS_MAXLENGTH_BY_CONTEXT, InputsContext, MessagingSystemUserIds } from 'shared-lib'
import { delayUnitSelectOptions, ISendMessageFormData } from './job-automation.model'
import { MultiMediaMarkdownEditorComponent } from '@engineering11/multimedia-web'

export interface IAutomationFormConfig {
  senderOptions: ISelectOption[]
  canEditMessages: boolean
  togglePosition?: 'top' | 'bottom'
  showStandaloneToggle?: boolean
}

function sendMessageToggleLayout(defaults: Partial<ISendMessageFormData>, showStandaloneToggle?: boolean) {
  return e11FormLayoutFullWidth(
    [
      e11FieldInputSwitch('enabled', 'Enabled', defaults.enabled, {
        validatorOrOpts: [],
        hideInputError: true,
      }),
    ],
    {
      templateSpec: [
        {
          templateKey: 'messageFootNoteTemplate',
          templatePosition: 'bottom',
        },
      ],
      containerClass: !showStandaloneToggle ? 'e11-hidden' : '',
    }
  )
}

type OnFileUploadFn = (arg: IMarkdownFile[]) => void
type onFileRemoveFn = (arg: IMarkdownFile) => void

export type MultiMediaArgs = {
  onFileUpload: OnFileUploadFn
  onFileRemove: onFileRemoveFn
}

type SendMessageFormDataForBuilder = Omit<ISendMessageFormData, 'attachments'>

export function sendMessageForm(
  defaults: Partial<SendMessageFormDataForBuilder>,
  config: IAutomationFormConfig,
  multimediaArgs?: Partial<MultiMediaArgs>
): E11FormForType<SendMessageFormDataForBuilder> {
  const { canEditMessages, senderOptions } = config

  // Do not need to upgrade if we have already upgraded
  const upgradeProductTemplate: IFormTemplateSpec[] = !canEditMessages
    ? [
        {
          templateKey: 'upgradeProductTemplate',
          templatePosition: 'bottom',
        },
      ]
    : []
  const resetFormTemplateSpec: IFormTemplateSpec[] = canEditMessages
    ? [
        {
          templateKey: 'resetFormTemplate',
          templatePosition: 'bottom',
        },
      ]
    : []

  const videoUrilPreviewTemplateSpec: IFormTemplateSpec[] = [
    {
      templateKey: 'videoUrlPreviewTemplate',
      templatePosition: 'bottom',
    },
  ]

  const togglePosition = config.togglePosition ?? 'top'
  const toggleLayout = sendMessageToggleLayout(defaults, config.showStandaloneToggle)

  return e11FormFor<SendMessageFormDataForBuilder>()([
    togglePosition === 'top' ? toggleLayout : { fields: [] },
    e11LayoutGroup(
      [
        e11LayoutGroup([
          {
            fields: [],
            layouts: [
              {
                fields: [getMessageControl(defaults, config, multimediaArgs)],
                templateSpec: [],
              },
            ],
          },
        ]),
        e11LayoutGroup([
          e11FormLayoutInlineGrid(
            [
              e11FieldInputNumber('delayDuration', 'Delay time', defaults.delayDuration, {
                validatorOrOpts: [Validators.required, Validators.min(0)],
                hideInputError: true,
                isDisabled: !canEditMessages,
              }),
              e11FieldInputSelect('delayUnits', 'Units', delayUnitSelectOptions, defaults.delayUnits, {
                validatorOrOpts: [Validators.required],
                hideInputError: true,
                isDisabled: !canEditMessages,
              }),
            ],
            {
              fieldsClass: 'e11-w-1/2',
              containerClass: 'xl:e11-w-1/2 e11-mb-4',
              templateSpec: [
                {
                  templateKey: 'delayTimeHeaderTemplate',
                  templatePosition: 'top',
                },
              ],
            }
          ),
          e11FormLayoutFullWidth(
            [
              e11FieldInputSelect('senderId', 'This message is from', senderOptions, defaults.senderId, {
                hideInputError: true,
                isDisabled: !canEditMessages,
                validatorOrOpts: [Validators.required],
              }),
            ],
            {
              fieldsClass: 'e11-w-2/3',
              containerClass: 'xl:e11-w-2/3 e11-mb-4',
            }
          ),
        ]),
      ],
      {
        layoutContainerClass: 'e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4 ',
        templateSpec: [...videoUrilPreviewTemplateSpec, ...resetFormTemplateSpec, ...upgradeProductTemplate],
      }
    ),
    togglePosition === 'bottom' ? toggleLayout : { fields: [] },
  ])
}

export const systemSenderOption: ISelectOption = { name: 'Hiring Team', value: MessagingSystemUserIds.hiring_team }

function getMessageControl(
  defaults: Partial<SendMessageFormDataForBuilder>,
  config: IAutomationFormConfig,
  multimediaArgs?: Partial<MultiMediaArgs>
) {
  const { onFileUpload, onFileRemove } = multimediaArgs || {}

  const extractIMarkdownFile = (data: IEditorMessage) => data.files

  if (config.canEditMessages)
    return e11FieldInputDynamicComponent<'message', string, MultiMediaMarkdownEditorComponent>(
      'message',
      'Message',
      MultiMediaMarkdownEditorComponent,
      defaults.message || '',
      {
        extraInputOption: {
          fieldClassList: ['e11-h-24'],
          editorOption: { footer: { action: [{ visible: false }] } },
          onUploadAction: e11FormEventHelper((data: IEditorMessage) => onFileUpload?.(extractIMarkdownFile(data))),
          onFileRemoveAction: e11FormEventHelper(onFileRemove || (() => {})),
        },
        validatorOrOpts: [Validators.required, Validators.maxLength(INPUTS_MAXLENGTH_BY_CONTEXT[InputsContext.LargeText])],
      }
    )
  else
    return e11FieldInputTextArea('message', 'Message', defaults.message || '', {
      extraInputOption: { inputClass: 'e11-h-24' },
      isDisabled: true,
      validatorOrOpts: [Validators.required],
    })
}
