import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { E11DynamicFormBuilderComponent, E11DynamicFormModule, E11FormForType } from '@engineering11/ui-lib/e11-form-builder'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationUpgradePromptComponent } from '@employer/app/components/jobs-automation/partials/automation-upgrade-prompt.component'
import { IAutomationFormConfig, sendMessageForm } from '@employer/app/components/jobs-automation/automation-form-message'
import { TranslateModule } from '@ngx-translate/core'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { IMarkdownReceiptFile, ISendMessageFormData } from '../../../job-automation.model'
import { autoQuestionFormDefaults } from '../../auto-questions.model'
import { AutomationVideoUrlPreviewComponent } from '../../../partials/automation-video-url-preview'
import { IFileReceipt } from '@engineering11/files-web'
import { IFile, IMarkdownFile } from '@engineering11/ui-lib/e11-markdown-editor'

@Component({
  selector: 'automated-question-message',
  standalone: true,
  imports: [
    CommonModule,
    E11DynamicFormModule,
    AutomationDelayInfoComponent,
    AutomationVideoUrlPreviewComponent,
    AutomationUpgradePromptComponent,
    TranslateModule,
    E11TooltipModule,
  ],
  template: `
    <ng-container *ngIf="forms">
      <div class=" e11-mt-6 e11-mb-2">
        <span *ngIf="title"> {{ title }}</span>
      </div>
      <e11-dynamic-form-builder
        #messageForm
        [fieldGroups]="forms"
        [initialValue]="formData"
        [loading]="loading"
        [hideSubmitMenu]="true"
        (valueChanges)="handleFormChange($event)"
      >
        <ng-template e11Template="delayTimeHeaderTemplate">
          <automation-delay-info></automation-delay-info>
        </ng-template>
        <ng-template e11Template="upgradeProductTemplate">
          <automation-upgrade-prompt></automation-upgrade-prompt>
        </ng-template>
        <ng-template e11Template="resetFormTemplate">
          <div class="e11-flex e11-gap-4 e11-text-sm e11-mb-4">
            <e11-tooltip [color]="'dark'" [tooltipText]="tooltipText">
              <button *ngIf="!loading" (click)="onResetFormData()" type="button" class="e11-text-skin-primary-accent e11-flex e11-items-center">
                <span class="material-icons-outlined md-18">replay</span>
                {{ 'Reset' | translate }}
              </button>
            </e11-tooltip>
          </div>
        </ng-template>
        <ng-template e11Template="videoUrlPreviewTemplate">
          <automation-video-url-preview
            [fileReceipts]="messageData.attachments"
            [loading]="loading"
            (removeFileReceipt)="removeFile($event)"
          ></automation-video-url-preview>
        </ng-template>
      </e11-dynamic-form-builder>
    </ng-container>
  `,
})
export class AutomatedQuestionMessage implements OnChanges, AfterViewInit {
  @ViewChild('messageForm') messageForm!: E11DynamicFormBuilderComponent<ISendMessageFormData>

  @Input() title: string = ''
  @Input() tooltipText: string = ''
  @Input() loading: boolean = false
  @Input() hideSubmitMenu: boolean = true

  @Input({ required: true }) formData!: ISendMessageFormData
  @Input({ required: true }) defaultValue!: ISendMessageFormData
  @Input({ required: true }) uploadAttachments!: (file: File) => Promise<IFileReceipt>

  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = config
    if (this.defaultValue) {
      this.forms = this.buildForms(config)
    }
  }

  @Output() onUploadVideo = new EventEmitter<boolean>()

  removeFile(fileId: string) {
    if (!this.messageData.attachments) return
    this.messageData = {
      ...this.messageData,
      attachments: (this.messageData.attachments ?? []).filter(f => f.id !== fileId),
    }
  }

  forms?: E11FormForType<ISendMessageFormData>

  messageData: ISendMessageFormData = this.formData
  tempFiles: IMarkdownReceiptFile[] = []

  async handleFormChange(value: ISendMessageFormData) {
    this.messageData = {
      ...this.messageData,
      ...value,
    }
  }

  onResetFormData() {
    this.formData = { ...autoQuestionFormDefaults.initialMessage }
    this.messageForm.reset(this.formData)
  }

  private buildForms(config: IAutomationFormConfig) {
    return sendMessageForm(
      this.formData,
      { ...config, showStandaloneToggle: true, togglePosition: 'bottom' },
      {
        onFileUpload: this.onFileUpload.bind(this),
        onFileRemove: this.onFileRemove.bind(this),
      }
    )
  }

  async onFileUpload(markdownFiles: IMarkdownFile[]) {
    const markdownFile = this.getTheUploadedFile(markdownFiles)
    if (!markdownFile) return
    const attachment = await this.uploadAttachments(markdownFile.file)
    const tempFile: IMarkdownReceiptFile = {
      id: markdownFile.id,
      receiptFile: attachment,
    }
    this.tempFiles = [tempFile].concat(this.tempFiles)
  }

  onFileRemove(markdownFile: IMarkdownFile) {
    const tempFile = this.tempFiles.find(f => f.id === markdownFile.id)
    if (tempFile) {
      this.tempFiles = this.tempFiles.filter(f => f.id !== markdownFile.id)
    }
  }

  getAllAttachments(): IFileReceipt[] {
    return this.tempFiles.map(f => f.receiptFile).concat(this.messageData.attachments ?? [])
  }

  resetTempFiles() {
    this.tempFiles = []
  }

  getData(): ISendMessageFormData {
    const data = {
      ...this.messageData,
      attachments: this.getAllAttachments(),
    }
    this.resetTempFiles()
    return data
  }

  getTheUploadedFile(files: IMarkdownFile[]): IMarkdownFile | undefined {
    return files[0]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formData && this._formConfig) {
      this.forms = this.buildForms(this._formConfig)
      this.messageData = this.formData
    }
  }

  ngAfterViewInit(): void {
    if (this.defaultValue && this._formConfig) {
      this.forms = this.buildForms(this._formConfig)
    }
  }
}
